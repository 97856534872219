@tailwind base;
@tailwind components;
@tailwind utilities;

*::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  border-radius: 8px;
  background-color: #f5f5f5;
}

*::-webkit-scrollbar {
  -webkit-appearance: none;
  -webkit-overflow-scrolling: auto;
  appearance: none;
  width: 10px;
  background-color: #f5f5f5;
}

*::-webkit-scrollbar-thumb {
  border-radius: 8px;
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: #555;
}
