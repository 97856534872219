.carousel .slide .legend {
  background-color: white !important;
  opacity: 1 !important;
  margin: 0 !important;
  width: max-content !important;
  transform: translateX(-50%) !important;
  padding: 0.25rem !important;
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
}

@media screen and (max-width: 768px) {
  .carousel .slide .legend {
    width: 90% !important;
    bottom: 50% !important;
    transform: translate(-50%, -50%) !important;
    left: 50% !important;
    top: 50% !important;
    height: min-content !important;
  }
}
